var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-root"},[_c('div',{staticClass:"login login-4 wizard d-flex flex-column flex-lg-row flex-column-fluid",attrs:{"id":"kt_login"}},[_c('div',{staticClass:"login-container d-flex flex-center flex-row flex-row-fluid order-2 order-lg-1 flex-row-fluid bg-white pt-15 pt-lg-9 pb-12 pb-lg-6"},[_c('router-view')],1),_c('div',{staticClass:"login-aside order-1 order-lg-2 bgi-size-cover bgi-no-repeat p-10 p-lg-10",style:({ backgroundImage: ("url(" + _vm.backgroundImage + ")") })},[_c('div',{staticClass:"d-flex flex-row-fluid flex-column justify-content-between h-100"},[_c('router-link',{attrs:{"to":{ name: 'dashboard' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"flex-column-auto mt-5 pb-lg-0 pb-10",attrs:{"href":href},on:{"click":navigate}},[_c('img',{staticClass:"max-h-70px",attrs:{"src":"media/logos/logo-letter-1.png","alt":""}})])]}}])}),_vm._m(0),_c('div',{staticClass:"d-flex justify-content-between mt-10 flex-wrap"},[_c('div',{staticClass:"opacity-70 font-weight-bold text-white pr-5"},[_vm._v(" © 2020 Central Core ")]),_c('div',{staticClass:"d-flex"},[_c('router-link',{attrs:{"to":{ name: 'privacy' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"text-white",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Privacy")])]}}])}),_c('router-link',{attrs:{"to":{ name: 'legal' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"text-white ml-10",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Legal")])]}}])}),_c('router-link',{attrs:{"to":{ name: 'contact' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"text-white ml-10",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Contact ")])]}}])})],1)])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-column-fluid d-flex flex-column justify-content-center"},[_c('h3',{staticClass:"font-size-h1 mb-5 text-white"},[_vm._v(" Bienvenue sur Central Core ")]),_c('p',{staticClass:"font-weight-lighter text-white opacity-80"},[_vm._v(" La meilleure application web pour partager vos fichiers ")])])}]

export { render, staticRenderFns }