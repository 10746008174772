<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-4 wizard d-flex flex-column flex-lg-row flex-column-fluid"
      id="kt_login"
    >
      <div
        class="login-container d-flex flex-center flex-row flex-row-fluid order-2 order-lg-1 flex-row-fluid bg-white pt-15 pt-lg-9 pb-12 pb-lg-6"
      >
        <router-view></router-view>
      </div>
      <div
        class="login-aside order-1 order-lg-2 bgi-size-cover bgi-no-repeat p-10 p-lg-10"
        :style="{ backgroundImage: `url(${backgroundImage})` }"
      >
        <div
          class="d-flex flex-row-fluid flex-column justify-content-between h-100"
        >
          <!--begin: Aside header-->
          <router-link :to="{ name: 'dashboard' }" v-slot="{ href, navigate }">
            <a
              :href="href"
              @click="navigate"
              class="flex-column-auto mt-5 pb-lg-0 pb-10"
            >
              <img
                src="media/logos/logo-letter-1.png"
                class="max-h-70px"
                alt=""
              />
            </a>
          </router-link>

          <!--end: Aside header-->
          <!--begin: Aside content-->
          <div
            class="flex-column-fluid d-flex flex-column justify-content-center"
          >
            <h3 class="font-size-h1 mb-5 text-white">
              Bienvenue sur Central Core
            </h3>
            <p class="font-weight-lighter text-white opacity-80">
              La meilleure application web pour partager vos fichiers
            </p>
          </div>
          <!--end: Aside content-->
          <!--begin: Aside footer for desktop-->
          <div class="d-flex justify-content-between mt-10 flex-wrap">
            <div class="opacity-70 font-weight-bold text-white pr-5">
              © 2020 Central Core
            </div>
            <div class="d-flex">
              <router-link
                :to="{ name: 'privacy' }"
                v-slot="{ href, navigate }"
              >
                <a :href="href" @click="navigate" class="text-white">Privacy</a>
              </router-link>
              <router-link :to="{ name: 'legal' }" v-slot="{ href, navigate }">
                <a :href="href" @click="navigate" class="text-white ml-10"
                  >Legal</a
                >
              </router-link>
              <router-link
                :to="{ name: 'contact' }"
                v-slot="{ href, navigate }"
              >
                <a :href="href" @click="navigate" class="text-white ml-10">
                  Contact
                </a>
              </router-link>
            </div>
          </div>
          <!--end: Aside footer for desktop-->
        </div>
      </div>
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-4.scss";
</style>

<script>
export default {
  name: "auth",
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/bg/bg-4.jpg";
    }
  }
};
</script>
